<template>
  <div>
    <footer id="footer" class="section-footer">
      <div class="container-custom footer">
        <div class="row-center">
          <nuxt-link to="/">
            <BaseImg src="/assets/images/logo.svg" class="brand-logo" alt="brand logo" />
          </nuxt-link>
          <p class="slogan-description">
            {{ FOOTER_CONTENT.SLOGAN_DESCRIPTION }}
          </p>
        </div>
        <div class="footer-navigation">
          <div v-for="item in FOOTER_NAVIGATION" :key="item.title" class="footer-section">
            <p class="section-title">
              {{ item.title }}
            </p>
            <div v-if="item.subCategories.length < 5">
              <div v-for="(category, index) in item.subCategories" :key="index">
                <nuxt-link v-if="category?.type === 'BUTTON'" class="button" :to="category.link">
                  <BaseImg class="button__icon" :src="category.icon" :alt="category.title" />
                  <p class="button__title">{{ category.title }}</p>
                </nuxt-link>
                <a
                  v-else
                  :id="category.title"
                  :key="category.title"
                  class="category-name"
                  :href="category.link"
                  :target="category?.type === 'BLANK' ? '_blank' : '_self'"
                >
                  {{ category.title }}
                </a>
              </div>
            </div>
            <div v-else>
              <div class="row-footer">
                <div class="column">
                  <a
                    v-for="category in item.subCategories.slice(0, 4)"
                    :id="category.title"
                    :key="category.title"
                    class="category-name"
                    :href="category.link"
                  >
                    {{ category.title }}
                  </a>
                </div>
                <div class="column-right">
                  <a
                    v-for="category in item.subCategories.slice(4)"
                    :id="category.title"
                    :key="category.title"
                    class="category-name"
                    :href="category.link"
                  >
                    {{ category.title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-center footer-deposit">
          <nuxt-link
            v-for="item in DEPOSIT_LIST"
            :id="item.title"
            :key="item.title"
            :to="item?.link"
            class="deposit-item"
            @click="handleClickMethod(item)"
          >
            <BaseImg class="deposit-image" :src="item.image" :alt="item?.title" />
            <p class="deposit-title">{{ item.title }}</p>
          </nuxt-link>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="footer-copyright__content">
          <BaseImg
            src="/assets/images/components/desktop/footer/dmca-protected.png"
            class="dmca-protected-image"
            alt="copy right"
          />
          <p class="copyright-text">{{ FOOTER_CONTENT.COPY_RIGHT }}</p>
          <div class="right-copyright">
            <BaseImg
              src="/assets/images/components/desktop/footer/copyright-logo.png"
              class="reserve-image"
              alt="copy right"
            />
            <a
              href="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
              title="DMCA.com Protection Status"
              class="dmca-badge"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useModal from '~/composables/useModal'
import { useAppStore } from '~~/store/app'
import { PAGE } from '~/constants/router'
import { FOOTER_NAVIGATION, DEPOSIT_LIST, FOOTER_CONTENT } from '@/constants/footer'
import BaseImg from '~/components/common/base-img'
import { MODAL_TYPES } from '~/config/constant'
const { openModalWithNavigate } = useModal()
const router = useRouter()
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)
const brandName = process.env.BRANCH_NAME

const handleClickMethod = (item: any) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else {
    navigateTo(item?.link)
  }
}

const openLink = (url: string) => {
  switch (url) {
    case 'LIVECHAT':
      // TODO
      break
    case 'TELEGRAM_CSKH':
      window.open(useRuntimeConfig().TELEGRAM_CSKH, '_blank')
      break
  }
}

const goHome = () => {
  router.push({ path: PAGE.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
