import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { usePopupApi } from '~/api/notification'
import { GAME_DISPLAY_TYPES } from '~/resources/supplier'
import { useLoading } from '@/composables/useLoading'
import { useAppStore } from '@/store/app'

export const useNotification = () => {
  const { isLoading, load, loading } = useLoading()
  const { $axios, $pinia, $device } = useNuxtApp()
  const { NOTIFICATION_INFO, USER_NOTIFICATION } = usePopupApi()
  const store = useAppStore($pinia)
  const appStoreRef = storeToRefs(store)
  const { isRead } = appStoreRef
  const listNotifications = ref([])
  const isReadNotif = ref()
  const lengthObjectsNotify = ref([])
  const openNotif = ref(false)
  const router = useRouter()

  const notifClasses = [
    'item-notif',
    'notif-numbers',
    'bell',
    'text-wrapper',
    'user-logged__notif',
    'content-item__title',
    'title-left',
    'title-right',
    'title-item',
    'content-item',
    'content-item__bell',
    'icon-up',
    'content-item__content',
    'created-time',
    'paragraph',
    'description',
    'group-notif__bottom',
    'brand-name'
  ]
  const isMobileOrTablet = $device.isMobileOrTablet
  const { setLengthNotification, setIsRead } = store
  const isShow = ref()

  const handleOpenNotif = (e) => {
    isReadNotif.value = JSON.parse(localStorage.getItem('listIdRead'))
    isShow.value = 0
    if (isMobileOrTablet) {
      router.push({ path: '/user-notification' })
    } else {
      openNotif.value = !openNotif.value
    }
  }

  const sortNotifications = () => {
    const json = localStorage.getItem('listIdRead')
    const idReadFromStorage = JSON.parse(json)
    listNotifications.value.sort((item, nextItem) => {
      const isItemRead = idReadFromStorage?.includes(item.id)
      const isNextItemRead = idReadFromStorage?.includes(nextItem.id)

      if (isItemRead && !isNextItemRead) {
        return 1
      } else if (!isItemRead && isNextItemRead) {
        return -1
      } else {
        return 0
      }
    })
  }
  const handleClickContent = (id) => {
    const listIdRead = []
    const json = localStorage.getItem('listIdRead')
    if (json) {
      const idReadFromStorage = JSON.parse(json)
      if (!idReadFromStorage?.includes(id)) {
        idReadFromStorage.push(id)
      }
      localStorage.setItem('listIdRead', JSON.stringify(idReadFromStorage))
    } else {
      const idReadFromStorage = []
      idReadFromStorage.push(id)
      localStorage.setItem('listIdRead', JSON.stringify(idReadFromStorage))
    }
    if (id !== isShow.value) {
      isShow.value = id
    } else {
      isShow.value = 0
    }
  }

  const getNotifications = async () => {
    try {
      const payload = queryString.stringify({
        display_type: GAME_DISPLAY_TYPES.pop_up
      })

      const res = await $axios.get(`${NOTIFICATION_INFO}?${payload}`)

      return res?.data?.data && res?.data?.data?.items && res?.data?.data?.items?.length ? res.data.data.items : []
    } catch (error) {
      return []
    }
  }
  const getUserNotifications = async () => {
    loading()
    try {
      const res = await $axios.get(`${USER_NOTIFICATION}`)
      if (res?.status === 200) {
        load()
        const filteredObjects = res.data.data.filter((object) => object.type !== 'odd')
        setLengthNotification(filteredObjects?.length)
        listNotifications.value = filteredObjects
        sortNotifications()
      } else {
        listNotifications.value = []
      }
    } catch (error) {
      load()
      return []
    }
  }

  watchEffect(() => {
    lengthObjectsNotify.value = listNotifications.value.reduce((acc, object) => {
      if (!isReadNotif.value?.some((id) => +id === object.id)) {
        acc.push(object)
      }
      return acc
    }, [])
  })

  onMounted(() => {
    isReadNotif.value = JSON.parse(localStorage.getItem('listIdRead'))
  })

  return {
    isLoading,
    isShow,
    getNotifications,
    handleClickContent,
    sortNotifications,
    listNotifications,
    notifClasses,
    getUserNotifications,
    handleOpenNotif,
    isReadNotif,
    lengthObjectsNotify,
    openNotif
  }
}
