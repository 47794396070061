import { PAGE } from './router'

export const HEADER_MENU = [
  {
    id: 'sport',
    title: 'Thể thao',
    icon: '/assets/images/components/desktop/icon-sport.png',
    loginRequired: false,
    newTab: false,
    link: PAGE.SPORTS,
    route: PAGE.SPORTS,
    type: 'url'
  },
  {
    id: 'live-casino',
    title: 'Live Casino',
    icon: '/assets/images/components/desktop/icon-live-casino.png',
    loginRequired: false,
    newTab: false,
    link: `${PAGE.CASINO}/all`,
    route: PAGE.CASINO,
    type: 'casino'
  },
  {
    id: 'games',
    title: 'Cổng Game',
    icon: '/assets/images/components/desktop/icon-games.png',
    loginRequired: true,
    newTab: true,
    link: PAGE.GAME,
    api: '/athena/esportsUrl',
    type: 'game'
  },
  {
    title: 'Khuyến Mãi',
    icon: '/assets/images/components/desktop/icon-promotion.png',
    loginRequired: false,
    newTab: false,
    type: 'lode',
    link: PAGE.PROMOTION
  }
]
