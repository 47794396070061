<template>
  <div id="header" class="header">
    <div class="header__container container-custom">
      <div class="header-menu">
        <nuxt-link id="logo" class="logo" :to="PAGE.HOME">
          <BaseImg :src="LOGO_SITE_IMAGE" alt="logo" />
        </nuxt-link>
        <div class="menu">
          <div v-for="(item, index) in menus" :key="index" class="menu-item">
            <div :class="{ active: item.id === menuActive }" class="menu-item__content" @click="onClickMenu(item)">
              <BaseImg :src="item.icon" alt="icon" />
              <p class="title">{{ item.title }}</p>
            </div>
            <div v-if="item.subMenu && item.subMenu.length > 0" class="sub-menu">
              <div class="sub-menu__list">
                <div
                  v-for="(subItem, index) in item.subMenu"
                  :id="`item-${index}`"
                  :key="index"
                  class="sub-menu__item"
                  @click="onClickSubMenu(subItem)"
                >
                  <BaseImg :src="subItem.image" alt="icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-button">
        <client-only>
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </client-only>
        <div id="notification-header" class="header-button__notif">
          <BaseImg
            id="bell"
            class="bell"
            :class="{ ring: isRing }"
            src="/assets/images/components/desktop/icon-noti.svg"
            alt="bell"
          />
          <div v-if="lengthObjectsNotify?.length" class="numbers">
            {{ lengthObjectsNotify?.length > 99 ? '99+' : lengthObjectsNotify?.length }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import { PAGE } from '~/constants/router'
import BaseImg from '~/components/common/base-img.vue'
import { useAppStore } from '@/store/app'
import { HEADER_MENU } from '~/constants/header'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import { IMenuHeader, ISubMenuHeader } from '~/types/menu.type'
import { useJackpot } from '~/composables/game/useJackpot'
import { useGameStore } from '~~/store/game'
import { LOBBY_GAME_URLS, LOBBY_CASINO_URLS } from '~/config/page-url'
import { IProvider } from '~/types/lobby.type'
import { PATH_ICON } from '~/constants/menu'
import { useGame } from '~/composables/game/useGame'
import { useCasino } from '~/composables/casino/useCasino'
import { useNotification } from '~/composables/useNotification'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot, currentUser, isLogged } = storeToRefs(store)
const storeGame = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGame)
const router = useRouter()
const openSubMenu = ref('')
const subMenu = ref(null)
const { fetchSumJackpot } = useJackpot()
const { fetchCongGame } = useGame()
const { fetchGameCasino } = useCasino()
const { lengthObjectsNotify } = useNotification()
const route = useRoute()
const menuActive = ref('home')
const isRing = ref(false)

let interval

const menus = computed(() =>
  HEADER_MENU.map((item) => {
    if (item.id === 'games') {
      return {
        ...item,
        subMenu: providerGames.value.map((subItem: IProvider) => ({
          title: subItem.name,
          image: subItem.icon,
          url: LOBBY_GAME_URLS[subItem.alias],
          alias: subItem.alias
        }))
      }
    }
    if (item.id === 'live-casino') {
      return {
        ...item,
        subMenu: providerCasino.value.map((subItem: IProvider) => ({
          title: subItem.name,
          image: subItem.icon,
          url: LOBBY_GAME_URLS[subItem.alias],
          alias: subItem.alias
        }))
      }
    }
    return item
  })
)

const ringBell = () => {
  interval = setInterval(() => {
    isRing.value = !isRing.value
  }, 5000)
}
const checkMenuActive = () => {
  for (const item of HEADER_MENU) {
    if (route.path.startsWith(item.route)) {
      menuActive.value = item.id
    }
  }
}
const fetchData = () => {
  if (providerGames.value.length === 0) {
    fetchCongGame()
  }
  if (providerCasino.value.length === 0) {
    fetchGameCasino()
  }
}
const onClickMenu = (item: IMenuHeader) => {
  menuActive.value = item.id
  router.push(item?.link)
}
const onClickSubMenu = (item: ISubMenuHeader) => {
  router.push(item.url)
}

onMounted(() => {
  checkMenuActive()
  ringBell()

  fetchSumJackpot()
  fetchData()
})

onUnmounted(() => {
  clearInterval(interval)
})

const listLink = reactive(HEADER_MENU)

watchEffect(() => {
  const updateSubMenu = (submenu: ISubMenuHeader[], type: string) => {
    const targetItem = listLink.find((item) => item.type === type)
    if (targetItem && submenu?.length) {
      const updatedSubmenu = submenu.map((subItem: ISubMenuHeader) => ({
        ...subItem,
        icon: {
          inactive: `${PATH_ICON}icon-${subItem.alias}.svg`,
          active: `${PATH_ICON}icon-${subItem.alias}-active.svg`
        }
      }))
      targetItem.subMenu = isLogged.value
        ? updatedSubmenu
        : updatedSubmenu.filter((item: ISubMenuHeader) => item?.alias !== 'recently')
    }
  }

  const subMenusToUpdate = [
    { submenu: providerGames.value, type: 'games' },
    { submenu: providerCasino.value, type: 'livecasino' }
  ]
  subMenusToUpdate.forEach(({ submenu, type }) => updateSubMenu(submenu, type))
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/header/index.scss"></style>
