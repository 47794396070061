export const GAME_DISPLAY_TYPES = {
  feature_game: 1,
  tables: 2,
  slots: 3,
  other: 4,
  in_game: 5,
  sexy_slot: 6,
  lottery: 7,
  lo_de: 8,
  fishing: 9,
  fighter: 12,
  slider: 10,
  home_slider: 11,
  hero_banner: 13,
  instance_game: 14,
  game_bai: 15,
  battle_game: 16,
  hot_game: 17,
  scratch_cards: 18,
  trading: 19,
  new_games: 20,
  most_play: 21,
  pop_up: 22,
  '3d': 23,
  jackpot: 24
}

export const GAME_PROVIDER = {
  vingame: {
    text: 'Techplay',
    value: 'vingame',
    icon: '/assets/images/components/common/game_provider/vingame.svg'
  },
  pragmatic: {
    text: 'Pragmatic',
    value: 'pragmatic',
    icon: '/assets/images/components/common/game_provider/pragmatic.svg'
  },
  playngo: {
    text: 'PLAY’nGO',
    value: 'playngo',
    icon: '/assets/images/components/common/game_provider/go.svg'
  },
  onegame: {
    text: 'Onegame',
    value: 'onegame',
    icon: '/assets/images/components/common/game_provider/onegame.svg'
  },
  spinomenal: {
    text: 'Spinomenal',
    value: 'spinomenal',
    icon: '/assets/images/components/common/game_provider/spinomenal.svg'
  },
  jdb: {
    text: 'JDB Gaming',
    value: 'jdb',
    icon: '/assets/images/components/common/game_provider/jdb.svg'
  },
  mg_slot: {
    text: 'MicroGaming',
    value: 'mg_slot',
    icon: '/assets/images/components/common/game_provider/mg_slot.svg'
  },
  cq9: {
    text: 'CQ9',
    value: 'cq9',
    icon: '/assets/images/components/common/game_provider/cq9.svg'
  },
  rik: {
    text: 'Ric',
    value: 'rik',
    icon: '/assets/images/components/common/game_provider/rik.svg'
  },
  wazdan: {
    text: 'Wazdan',
    value: 'wazdan',
    icon: '/assets/images/components/common/game_provider/wazdan.svg'
  },
  habanero: {
    text: 'Habanero',
    value: 'habanero',
    icon: '/assets/images/components/common/game_provider/habanero.svg'
  },
  netent: {
    text: 'Netent',
    value: 'netent',
    icon: '/assets/images/components/common/game_provider/netent.svg'
  },
  redtiger: {
    text: 'Redtiger',
    value: 'redtiger',
    icon: '/assets/images/components/common/game_provider/redtiger.svg'
  }
}

export const SORT_LIST_GAME_OPTIONS = [
  {
    text: 'Tất cả',
    value: '',
    valueUrl: '',
    icon: 'icon-game-hot-all'
  },
  {
    text: 'Xu hướng',
    value: 'TOP_USER_BET',
    valueUrl: 'top-game-dang-trend',
    icon: 'icon-game-hot-trend'
  },
  {
    text: 'Yêu thích',
    value: 'HOT_GAME',
    valueUrl: 'top-game-duoc-yeu-thich-nhat',
    icon: 'icon-icon-game-heart'
  },
  {
    text: 'Cược nhiều',
    value: 'TOP_BET',
    valueUrl: 'top-game-nhieu-nguoi-cuoc-nhat',
    icon: 'icon-game-hot-favourite'
  },
  {
    text: 'Mới ra lò',
    value: 'NEW_GAME',
    valueUrl: 'top-game-moi-ra-lo',
    icon: 'icon-game-hot-new'
  },
  {
    text: 'Newber',
    value: 'FOR_NEWBER',
    valueUrl: 'top-game-cho-nguoi-moi',
    icon: 'icon-icon-game-newber'
  }
]

export function getGameCategory() {
  const { $config } = useNuxtApp()
  const GAME_CATEGORY = {
    'xo-so': {
      name: 'Xổ Số',
      display_type: GAME_DISPLAY_TYPES.lo_de,
      alias: 'xo-so',
      title: 'Xổ Số Ba Miền, tiền về bất tận',
      description: `Xổ Số Ba Miền, Xổ Số Siêu Tốc tại nhà cái ${$config.BRANCH_NAME} là cơ hội làm giàu đỉnh cao cho toàn bộ người chơi. Ghi số đề Miền Bắc, Miền Nam, Miền Trung mỗi ngày để trúng ngay tiền tỷ.`,
      provider: [],
      lineWidth: 138,
      lineWidthMB: 98,
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-xoso.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/xo-so.webp'
    },
    ingame: {
      name: 'INGAME',
      display_type: GAME_DISPLAY_TYPES.in_game,
      alias: 'ingame',
      title: `INGAME ${$config.BRANCH_NAME} - Nơi hội tụ mọi thể loại bàn chơi xanh chín`,
      description: `Trở thành tỷ phú mỗi ngày tại nhà cái ${$config.BRANCH_NAME}, nơi cung cấp bàn chơi INGAME đủ mọi trò chơi xanh chín bậc nhất như: Tài Xỉu, Xóc Đĩa, Bầu Cua, Baccarat, Xì Dách,... Tham gia ngay, thắng tiền tỷ mỗi ngày.`,
      provider: [],
      lineWidth: 145,
      lineWidthMB: 103,
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-ingame.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/ingame.webp'
    },
    'game-nhanh': {
      name: 'Game Nhanh',
      display_type: GAME_DISPLAY_TYPES.instance_game,
      alias: 'game-nhanh',
      title: `${$config.BRANCH_NAME} - Game Nhanh, chơi nhanh, thắng lớn`,
      description: `Tham gia cổng Game Nhanh để x10, x50, x900 tiền vốn tại nhà cái ${$config.BRANCH_NAME} với các trò chơi hấp dẫn như: Trading Bitcoin, Máy Bay, Dò Mìn, Trên Dưới, Aviator,... và nhiều thể loại khác. Chơi nhanh, thắng lớn.`,
      provider: [],
      lineWidth: 65,
      lineWidthMB: 48,
      lineText: '/assets/images/components/common/line-text-2.svg',
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-game-nhanh.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/gamenhanh.webp'
    },
    'game-bai': {
      name: 'Game Bài',
      display_type: GAME_DISPLAY_TYPES.game_bai,
      alias: 'game-bai',
      title: `Game Bài ${$config.BRANCH_NAME} - Bàn chơi Bài đổi thưởng hiện đại`,
      description:
        'Đủ các thể loại Game Bài phổ biến nhất hiện nay như: Binh Xập Xám, Liêng 3 Cây, Phỏm Ù, Tiến Lên Miền Nam, Sâm Lốc,... với hệ thống xếp bàn chống vây chuyên nghiệp, trải nghiệm mượt mà. Tham gia ngay!',
      provider: [],
      lineWidth: 154,
      lineWidthMB: 111,
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-game-bai.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/gamebai.webp'
    },
    keno: {
      name: 'Keno, Quay Số',
      display_type: GAME_DISPLAY_TYPES.lottery,
      alias: 'keno',
      title: 'Keno, Quay Số - Đa dạng thể loại, thưởng lớn mỗi ngày',
      description: `Tham gia trò chơi con số trúng thưởng với thể loại Keno, Quay Số tại nhà cái ${$config.BRANCH_NAME} hôm nay để sở hữu những tấm vé trúng trăm triệu mỗi ngày. Dễ chơi, dễ trúng, trả thưởng trong 30 giây.`,
      provider: [],
      lineWidth: 133,
      lineWidthMB: 95,
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-keno.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/keno.webp'
    },
    'no-hu': {
      name: 'Nổ Hũ',
      display_type: GAME_DISPLAY_TYPES.slots,
      alias: 'no-hu',
      title: `Nổ Hũ tại nhà cái ${$config.BRANCH_NAME} - Cơ hội thành tỷ phú trong giây lát`,
      description: `Hàng trăm tựa game Nổ Hũ tiền tỷ với các máy quay hũ số một thị trường, đồ họa game hấp dẫn, hũ lớn nổ mỗi ngày, săn ngay cơ hội thành tỷ phú tại ${$config.BRANCH_NAME}.`,
      provider: [GAME_PROVIDER.vingame, GAME_PROVIDER.rik],
      lineWidth: 220,
      lineWidthMB: 155,
      lineText: '/assets/images/components/common/line-text-3.svg',
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-nohu.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/no-hu.webp'
    },
    slots: {
      name: 'Slots',
      display_type: GAME_DISPLAY_TYPES.slots,
      alias: 'slots',
      title: `Slots tại ${$config.BRANCH_NAME} - Hàng trăm máy quay Slots tới từ những nhà cung cấp hàng đầu`,
      description:
        'Hơn trăm tựa game Slots hấp dẫn, đồ họa đỉnh cao tới từ những nhà cung cấp máy chơi Slots hàng đầu. Tham gia ngay hôm nay, trúng thưởng lớn mỗi ngày.',
      provider: [],
      lineWidth: 140,
      lineWidthMB: 100,
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-slots.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/slots.webp'
    },
    'ban-ca': {
      name: 'Bắn Cá',
      display_type: GAME_DISPLAY_TYPES.fishing,
      alias: 'ban-ca',
      title: `Bắn Cá trực tuyến ${$config.BRANCH_NAME} - Săn hũ lớn mỗi ngày`,
      description: `Bắn Cá nay đã có trên giao diện Online tại nhà cái ${$config.BRANCH_NAME}, với giao hiện đồ họa hiện đại, nhiều cảnh, nhiều màn chơi cùng hàng vạn loài cá khủng. Tham gia ngay, săn cá VIP, trúng nổ hũ mỗi ngày!`,
      provider: [],
      lineWidth: 230,
      lineWidthMB: 163,
      lineText: '/assets/images/components/common/line-text-3.svg',
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-banca.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/ban-ca.webp'
    },
    'table-games': {
      name: 'Table Games',
      display_type: GAME_DISPLAY_TYPES.tables,
      alias: 'table-games',
      title: `Table Games  - Bàn chơi bài chuyên nghiệp tại nhà cái ${$config.BRANCH_NAME} `,
      description:
        'Cung cấp nhiều thể loại bàn chơi bài phổ biến nhất của Casino như bàn chơi Baccarat, Blackjack, Poker,... Cơ hội chiến thắng luôn luôn nằm trong tay bạn.',
      provider: [],
      lineWidth: 125,
      lineWidthMB: 89,
      iconTitle: '/assets/images/components/desktop/pages/lobby/lobby-games/icon-table-games.svg',
      icon: '/assets/images/components/mobile/pages/lobby/lobby-menu/table.webp'
    }
  }
  return { GAME_CATEGORY }
}
